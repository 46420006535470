/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"

import { useStaticQuery, graphql } from "gatsby"
import getDate from '../helpers/dates'


function Seo({ description, lang, meta, title, image, url, data, slug }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            url 
          }
        }
      }
    `
  )


  let rawDate = null
  rawDate = data?.pageEvent && data?.pageEvent.attendingNb && getDate(data?.pageEvent.date)
  let date = null

  date = rawDate && '🔴' + rawDate.ParisDate.split(' ').slice(0, 2).join(' ') + ' ' + rawDate.ParisTime + ' (heure de Paris) '

  const metaDescription = description || site.siteMetadata.description || ''
  const defaultTitle = site.siteMetadata?.title

  const pageUrl = slug ? `${site.siteMetadata?.url}${slug}` : null







  return (
    <>
      <title>{title}</title>
      <meta property='og:url' content={pageUrl} />
      <meta property='og:title' content={date ? date + title : title} />
      <meta property='og:image' content={`${site.siteMetadata?.url}${image}`} />
      <meta property='og:description' content={metaDescription} />
      
      <meta property='og:type' content={'website'} />
      <meta property='og:myId' content='1234-567' />
    </>
  )
}




export default Seo
