import React from "react"
import { graphql } from 'gatsby'
import Box from '@mui/material/Box'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import '../components/layout'
import Layout from "../components/layout"
import Seo from "../components/seo"
import FrontOfficeTheme from '../style/globalTheme'

export const Head = ({ location, params, data, pageContext }) => (
  <Seo title="Confidentialité" />
  )

const ConfidentialitePage = ({ data }) => {



  // if (!data)
  //     return null

  const Html = data.docs.edges.find(({ node }) => node.frontmatter.title === 'confidentialite').node.html




  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={FrontOfficeTheme}>

        <Layout data={data}>

          <Box margin="15%">
            <div dangerouslySetInnerHTML={{ __html: Html }} />
          </Box>



        </Layout>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default ConfidentialitePage

export const query = graphql`
 query {
  docs: allMarkdownRemark(filter: {frontmatter: {type: {eq: "legal"}}}) {
    edges {
      node {
        html
        frontmatter {
          type
          title
        }
      }
    }
  }
 }
`
